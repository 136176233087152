const CENTS_PER_DOLLAR = 100;

export const formatAsDollars = (value: number, options = { cents: true }): string => {
  const dollarAmount = options.cents ? value / CENTS_PER_DOLLAR : value;
  return `$${Number(dollarAmount.toFixed(2)).toLocaleString('en-US')}`;
};

export const toLocaleDateString = (value?: string) =>
  value ? new Date(value).toLocaleDateString() : '';

export const capitalize = (text: string): string => {
  return text.split('').reduce((result, char, index) => {
    if (index === 0) return result + char.toUpperCase();
    return result + char.toLowerCase();
  }, '');
};

export const currencyStringToNumber = (currencyString: string): number => {
  if (!currencyString) return 0;
  // Remove the dollar sign and commas, and strip any spaces
  const cleanedString =
    typeof currencyString === 'string'
      ? currencyString.replace(/[$,]/g, '').trim()
      : currencyString;

  // Convert the cleaned string to a number and multiply by 100 to eliminate the decimal
  const numberValue = Math.round(parseFloat(cleanedString) * 100);

  return numberValue;
};

export const generateRandomString = (length: number = 5): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const parseDateString = (dateString: string): Date => {
  const parts = dateString.split('-') as [string, string, string];

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
  const day = parseInt(parts[2], 10);

  return new Date(year, month, day);
};

export const setSemesterCodeString = (date: string) => {
  const dateString = parseDateString(date);
  const month = dateString.getMonth(); // 0-11, so January is 0, February is 1, etc.
  const year = dateString.getFullYear();

  let semester = '';
  if (month >= 0 && month <= 3) {
    semester = 'Spring';
  } else if (month >= 4 && month <= 6) {
    semester = 'Summer';
  } else {
    semester = 'Fall';
  }

  return `${semester} ${year}`;
};

export function snakeCase(str: string): string {
  return str
    // Insert an underscore between lower & upper case (e.g. "someKey" -> "some_Key")
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    // Convert spaces/dashes to underscores
    .replace(/[\s-]+/g, '_')
    // Convert everything to lowercase
    .toLowerCase();
}

export default null; // silence a false warning
